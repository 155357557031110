import React, { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { getRegister } from '../../API/CallsApi';
import { GlobalData } from '../../App';
import Swal from 'sweetalert2';


const schema = yup.object({
    name: yup.string().required("Enter Valid Name"),
    email: yup.string().required("Enter Valid Email"),
    mobile_no: yup.string().required("Enter Valid Number"),
    password: yup.string().required("Enter Valid Password").min(6, "Password length should be 6 characters"),
    confirmpassword: yup.string().oneOf([yup.ref("password")], "Passwords do not match")
}).required();

function Register() {
    const { authLog } = useContext(GlobalData);
    const naviget = useNavigate();

    useEffect(() => {
        if (authLog) {
            naviget("/")
        }
    }, [])

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        let result = await getRegister(data);
        if (result.statusText === "Created") {
            Swal.fire({
                icon: 'success',
                title: 'Good job!',
                text: 'Register in Successfully!',
                footer: 'Please Login',
            })
            naviget('/login');
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Login Faild, Please Try again!',
                footer: result.response.data.detail,
            })
            console("Register Error : ", result);
        }
    };

    return (
        <div className="Adminlog-components">
            <div className="Register-container">
                <h2 className="brandname">ROAD<span>SERVE</span></h2>
                <p className="brandtag">Fast | Reliable | Cheap</p>
                <p className="form-heading">Register</p>

                <form onSubmit={handleSubmit(onSubmit)} className="form">

                    <div className="items">
                        <input type="text" placeholder="Full Name" name="name" {...register("name")} className="input" />
                        <p>{errors.name?.message}</p>
                    </div>

                    <div className="items">
                        <input type="number" placeholder="Phone Number" name="mobile_no" {...register("mobile_no")} className="input number-fild" />
                        <p>{errors.mobile_no?.message}</p>
                    </div>

                    <div className="items">
                        <input type="email" placeholder="Email Address" name="email" {...register("email")} className="input" />
                        <p>{errors.email?.message}</p>
                    </div>

                    <div className="items">
                        <input type="password" placeholder="Create Password" name="password" {...register("password")} className="input" />
                        <p>{errors.password?.message}</p>
                    </div>

                    <div className="items">
                        <input type="password" placeholder="Confirm Password" name="confirmpassword" {...register("confirmpassword")} className="input" />
                        <p>{errors.confirmpassword?.message}</p>
                    </div>

                    <button type="submit" className="login-ntn">SignUp</button>
                </form>
                <div className="register-hear">
                    <h5>Or</h5>
                    <p>Already have an account? <span onClick={
                        () => naviget('/login')
                    }>Click here</span></p>
                </div>
            </div>
        </div>
    )
}

export default Register