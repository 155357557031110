import React, { useEffect, useState } from 'react';
import { Chart as ChartJs } from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { GlobalData } from '../../App';
import { useContext } from 'react';
function AllOrdersChart() {

    const { allCount } = useContext(GlobalData);
    const [data, setData] = useState({
        labels: [
            'Active Orders',
            'Not active Orders',
        ],
        datasets: [{
            label: 'Today Overall Orders',
            data: [5, 5],
            backgroundColor: [
                '#02B290',
                '#EF5354'
            ],
            hoverOffset: 4
        }]
    });
    useEffect(() => {
        if (allCount) {
            setData({
                labels: [
                    'Active Orders',
                    'Not active Orders',
                ],
                datasets: [{
                    label: 'Today Overall Orders',
                    data: [allCount.is_active_true_count_today, allCount.is_active_false_count_today],
                    backgroundColor: [
                        '#02B290',
                        '#EF5354'
                    ],
                    hoverOffset: 4
                }]
            });
        }
    }, [allCount]);
    
    return (
        <div>
            <Doughnut data={data} style={{ width: '47.5vh' }} className="Doughnut-chart"/>
        </div>
    )
}
export default AllOrdersChart;