import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Dna } from 'react-loader-spinner';
import { TOKEN } from '../../API/Index';
import { getAllCustomerData } from '../../API/CallsApi';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };


function AvailabledataTable() {

    const [page, setPage] = useState(1);
    const [dataPP, setDataPP] = useState(10);
    const [spinner, setSpinner] = useState(false);
    const [orderDetails, setOrderDetails] = useState(false);

    const handleChange = (event, value) => {
        setPage(value);
    };
    useEffect(() => {
        const token = localStorage.getItem(TOKEN);
        setSpinner(false);
        async function callapi() {
            let result = await getAllCustomerData(token, page, dataPP);
            setOrderDetails(result.data)
            if (result.data) {
                setSpinner(true);
            } else {
                setSpinner(false);
            }
            console.log("Order Result", result.data);
        }
        callapi();
    }, [page]);

    return (
        <>
            {
                spinner ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Customer Name</StyledTableCell>
                                    <StyledTableCell align="right">Address</StyledTableCell>
                                    <StyledTableCell align="right">Mobile</StyledTableCell>
                                    <StyledTableCell align="right">Pin</StyledTableCell>
                                    <StyledTableCell align="right">Model</StyledTableCell>
                                    <StyledTableCell align="right">Number</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderDetails.order_list.map((data) => (
                                    <StyledTableRow key={data._id.$oid}>
                                        <StyledTableCell scope="row">{data.name}</StyledTableCell>
                                        <StyledTableCell align="right">{data.address}</StyledTableCell>
                                        <StyledTableCell align="right">{data.contact_number}</StyledTableCell>
                                        <StyledTableCell align="right">{data.pin_code}</StyledTableCell>
                                        <StyledTableCell align="right">{data.car_model}</StyledTableCell>
                                        <StyledTableCell align="right">{data.car_number}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                            <Stack spacing={2}>
                                <Pagination
                                    count={orderDetails.total_pages}
                                    page={page}
                                    onChange={handleChange}
                                />
                            </Stack>
                        </div>
                    </TableContainer>
                ) : (
                    <Dna
                        visible={true}
                        height="200"
                        width="200"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                )

            }

        </>

    )
}

export default AvailabledataTable
