import React from 'react';
import AvailabledataTable from '../Components/By-Admin/AvailabledataTable';
function AvailableDatas() {
  return (
    <div className="AvailableDatas-Components">
      <AvailabledataTable/>
    </div>
  )
}

export default AvailableDatas
