import React from 'react';

function AllClosures() {
  return (
    <div className="AllClosures-Components">
        AllClosures
    </div>
  )
}

export default AllClosures;