import React from 'react';
import { FcBusinessman } from "react-icons/fc";
import { LogoutAlert } from '../../Functions/functions';


function ProfileBox() {
    const handleLogout = () => {
        LogoutAlert();
    };
    return (
        <div className="admin-profile">
            <div className="img">
                <FcBusinessman className="icon-img"/>
            </div>
            <div className="admin-profile__info">
                <p>Name: Rahul Biswas</p>
                <p>Number: 9732149101</p>
                <p>Email: rahul.b@techpax.in</p>
                <button className="up-btn">Update Profile</button>
                <button  className="up-btn">Change Password</button>
                <br />
                <button onClick={handleLogout} className="log-out">Logout</button>
            </div>
        </div>
    )
}

export default ProfileBox
