export const TOKEN = 'token';
export const BASE_URL = 'https://admin.roadserve.in/';
export const LOGIN_URL = `${BASE_URL}login`;
export const REGISTER_URL = `${BASE_URL}register_admin`;
export const ALL_COUNT_URL = `${BASE_URL}get_all_count`;
export const EMP_ACTIVATE_URL = `${BASE_URL}emp_verified`;
export const CHANGE_NUMBER_URL = `${BASE_URL}change_number`;



