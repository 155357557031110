import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { Dna } from 'react-loader-spinner';
import { getAllTLData, getEplStates } from '../../API/CallsApi';
import { TOKEN } from '../../API/Index';
import Swal from 'sweetalert2';

import { GlobalData } from '../../App';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 20,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));



function TLComponents() {

    const {tlState, setTlState} = useContext(GlobalData);

    const [TLDetails, setTLDetails] = useState();
    const [page, setPage] = useState(1);
    const [dataPP, setDataPP] = useState(10);
    const [spinner, setSpinner] = useState(false);

    async function callAPI() {
        setSpinner(false);
        const token = localStorage.getItem(TOKEN);
        let result = await getAllTLData(token, page, dataPP);
        setTLDetails(result.data)
        if (result.data) {
            setSpinner(true);
        } else {
            setSpinner(false);
        }
    }


    
    useEffect(() => {
        callAPI();
    }, [page]);

    useEffect(() => {
        callAPI();
    }, [tlState]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const status_handler = (_id, status) => {
        const token = localStorage.getItem(TOKEN);

        async function callapi() {
            let result = await getEplStates(token, _id, !status);
            setTlState(status);
        }

        if (status) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Deactivate!'
            }).then((result) => {
                if (result.isConfirmed) {
                    callapi();
                    setTimeout(() => {
                        Swal.fire(
                        'Deactivate',
                        'Deactivated Successfully.',
                        'success'
                    );
                    }, 2000);
                }
            })
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Activated!'
            }).then((result) => {
                if (result.isConfirmed) {
                    callapi();
                    setTimeout(() => {
                        Swal.fire(
                        'Activated!',
                        'Activated Successfully.',
                        'success'
                    );
                    }, 2000);
                }
            })
        }
    }

    return (
        <>
            {
                spinner ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align="right">Number</StyledTableCell>
                                    <StyledTableCell align="right">Employee count</StyledTableCell>
                                    <StyledTableCell align="right">Email</StyledTableCell>
                                    <StyledTableCell align="right">Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {TLDetails ? (
                                    TLDetails.tl_list.map((emp) => (
                                        <StyledTableRow key={emp._id.$oid}>
                                            <StyledTableCell scope="row">
                                                <Link to={`/employees/details`} state={{ id: emp._id.$oid }} style={{ textDecoration: "none", color: "black" }}>
                                                    {emp.name}
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{emp.mobile_no}</StyledTableCell>
                                            <StyledTableCell align="right">{emp.empployee_count}</StyledTableCell>
                                            <StyledTableCell align="right">{emp.email}</StyledTableCell>
                                            <StyledTableCell align="right">{emp.status}
                                                {
                                                    emp.verified ? (
                                                        <button className="Switch-btn active"
                                                            onClick={
                                                                () => {
                                                                    status_handler(emp._id.$oid, emp.verified)
                                                                }
                                                            }
                                                        >Active</button>
                                                    ) : (
                                                        <button className="Switch-btn inactive"
                                                            onClick={
                                                                () => {
                                                                    status_handler(emp._id.$oid, emp.verified)
                                                                }
                                                            }
                                                        >Inactive</button>
                                                    )
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )
                                    : null
                                }

                            </TableBody>
                        </Table>
                        <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                            <Stack spacing={2}>
                                <Pagination
                                    count={TLDetails.total_pages}
                                    page={page}
                                    onChange={handleChange}
                                />                            </Stack>
                        </div>
                    </TableContainer>
                ) : (
                    <Dna
                        visible={true}
                        height="200"
                        width="200"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                )
            }

        </>
    )
}

export default TLComponents;