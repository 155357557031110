import './App.scss';
import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthorProtected from './Components/ProtectedComponents/AuthorProtected';
import MiniDrawer from './Components/Navbar/MiniDrawer';
import Login from './Components/Form/Login';
import Register from './Components/Form/Register';
import AdminDash from './Pages/Dashbord';
import AllEmployees from './Pages/AllEmployees';
import AllOrders from './Pages/AllOrders';
import AllClosures from './Pages/AllClosures';
import Profile from './Pages/Profile';
import Notifications from './Pages/Notifications';
import EmployeeDetails from './Pages/EmployeeDetails';
import AvailableDatas from './Pages/AvailableDatas'
import { TOKEN } from './API/Index';

export const GlobalData = createContext();

function App() {

  const [authLog, setAuthLog] = useState(localStorage.getItem(TOKEN))
  const [allCount, setAllCount] = useState(0);
  const [searchstateUpadte, setSearchStateUpdate] = useState();
  const [empState, setEmpState] = useState();
  const [tmState, setTlState] = useState();
  const [orderState, setOrderState] = useState();

  const Authlog_Handler = (value) => {
    setAuthLog(value);
  }

  const setAllCounts_handler = (value) => {
    setAllCount(value);
  }

  const Emp_state_handler = (value) => {
    setEmpState(value);
  }

  const Tl_state_handler = (value) => {
    setTlState(value);
  }

  const Search_state_handler = (value) => {
    setSearchStateUpdate(value);
  }
  
  const order_state_handler = (value) => {
    setOrderState(value)
  }

  return (
    <GlobalData.Provider
      value={{

        // Send
        authLog: authLog,
        allCount: allCount,
        empState : empState,
        sarchState : searchstateUpadte,
        tlState : tmState,
        orderState : orderState,
        
        // Rece
        GetAuthlog: Authlog_Handler,
        setAllCounts: setAllCounts_handler,
        setEmpState : Emp_state_handler,
        setSearchState : Search_state_handler,
        setTlState : Tl_state_handler,
        setOrderState : order_state_handler,
      }}
    >
      <div className="App">
        <Router>

          {authLog ? <MiniDrawer /> : null}
          
          <Routes>

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route element={<AuthorProtected />}>

              <Route path="/" element={<AdminDash />} />
              <Route path="/employees" element={<AllEmployees />} />
              <Route path="/orders" element={<AllOrders />} />
              <Route path="/closures" element={<AllClosures />} />
              <Route path="/employees/details" element={<EmployeeDetails />} />
              <Route path="/orders/by-admin" element={<AvailableDatas />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/profile" element={<Profile />} />

            </Route>

          </Routes>
        </Router>
      </div>
    </GlobalData.Provider>
  );
}

export default App;
