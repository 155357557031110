import React, { useState, useContext} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GlobalData } from '../../App';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function TodayClosersTable() {

  const {allCount} = useContext(GlobalData);

  
  return (
    <TableContainer component={Paper} sx={{ maxHeight: '89vh', minHeight: '89vh' }} className="table-container">
      <Table sx={{ minWidth: '450px' }} stickyHeader aria-label="sticky table" className="Closures-table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="right">Closures</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allCount.emp_closure_count.map((emp) => (
            <StyledTableRow key={emp.employee_name}>
              <StyledTableCell scope="row">{emp.employee_name}</StyledTableCell>
              <StyledTableCell align="right">{emp.closures_count}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TodayClosersTable;