import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { GlobalData } from '../../App';
import { useContext } from 'react';
function CardOrderChart() {

    const [data, setData] = useState(
        {
            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            datasets: [{
                label: 'This Month Active Orders',
                data: [2, 5, 10, 15, 20, 17, 23, 26, 24, 30],
            }]
        }
    );
    
    const { allCount } = useContext(GlobalData);

    useEffect(() => {
        if (allCount) {
            setData({
                labels: allCount.order_count_by_day.date,
                datasets: [{
                    label: 'This Month Active Orders',
                    data: allCount.order_count_by_day.data,
                }]
            });
        }
    }, [allCount])


    return (
        <div>
            <Line data={data} style={{ height: '47.5vh' }} className="Line-chart" />
        </div>
    )
}

export default CardOrderChart;