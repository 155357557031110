import React from 'react';
import { Link } from 'react-router-dom';
import { FcOnlineSupport, FcInternal, FcViewDetails } from "react-icons/fc";
import AllOrdersChart from '../ChartComponents/AllOrdersChart';
import CardOrderChart from '../ChartComponents/CardOrderChart';
import TodayClosersTable from './TodayClosersTable';
import { GlobalData } from '../../App';
import { useContext } from 'react';

function HomeComponents() {
    
    const {allCount} = useContext(GlobalData);


    return (
        <div className="HomeComponents-container">
            <div className="left-container">
                <div className="container-top-items">
                    <Link className="item" to="/employees">
                        <FcOnlineSupport className="icons" />
                        <div className="devider">
                            <p>Total Employees</p>
                            <h5>{allCount.total_emp}</h5>
                        </div>
                    </Link>

                    <Link className="item" to="/orders">
                        <FcInternal className="icons" />
                        <div className="devider">
                            <p>Total Orders Today</p>
                            <h5>{allCount.today_total_order}</h5>
                        </div>
                    </Link>

                    <Link className="item" to="/orders/by-admin">
                        <FcViewDetails className="icons" />
                        <div className="devider">
                            <p>Available Data</p>
                            <h5>{allCount.total_orders_added_by_admin}</h5>
                        </div>
                    </Link>
                </div>
                <div className="containet-mid-item">
                    <CardOrderChart />
                    <AllOrdersChart />
                </div>
            </div>

            <div className="right-container">
                <TodayClosersTable />
            </div>

        </div >
    )
}

export default HomeComponents
