import React,{useEffect, useState} from 'react';
import { useLocation , useNavigate} from 'react-router-dom';

function EmployeeDetails() {

  const navigate = useNavigate();
  const location = useLocation();
  
  const [id, setId] = useState()
  const [hasId, setHasId] = useState(false)

  useEffect(() => {
    if (location.state == null) {
      navigate("/employees");
    } else {
      setId(location.state.emp_id)
      setHasId(true)
    }
  }, [])

  return (
    <div className="EmployeeDetails-Components ">
      {hasId ? (
        <p>Employee is {id}</p>
      ) : null}
    </div>
  )
}

export default EmployeeDetails;