import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Dna } from 'react-loader-spinner';
import { TOKEN } from '../../API/Index';
import { getAllOrderData, getChangeNumber, getCancelBooking } from '../../API/CallsApi';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { getSearchOrders } from '../../API/CallsApi';
import { GlobalData } from '../../App';
import { BASE_URL } from '../../API/Index';
import axios, { AxiosError } from 'axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function OrdersTable() {

    const { orderState, setOrderState } = useContext(GlobalData);

    const [page, setPage] = useState(1);
    const [ordersDetails, setOrdersDetails] = useState(false);
    const [dataPP, setDataPP] = useState(10);
    const [spinner, setSpinner] = useState(false);
    const [inputval, setInputval] = useState('');
    const [searRes, setSearRes] = useState(false);

    async function calAPI() {
        const token = localStorage.getItem(TOKEN);
        setSpinner(false);
        let result = await getAllOrderData(token, page, dataPP);
        setOrdersDetails(result.data);
        console.log("result.data", result.data)
        if (result.data) {
            setSpinner(true);
        } else {
            setSpinner(false);
        }
    }

    useEffect(() => {
        calAPI();
    }, [page]);

    useEffect(() => {
        calAPI();
    }, [orderState]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const editNumber_handler = (_id, num) => {

        const token = localStorage.getItem(TOKEN);
        async function callapi(token, _id, value) {
            let result = await getChangeNumber(token, _id, value);
            setOrderState(!orderState);
        }

        Swal.fire({
            title: 'Enter New Mobile Number',
            input: 'number',
            inputLabel: 'Change Number',
            inputValue: num,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write something!'
                } else {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Change!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            callapi(token, _id, value);
                            setTimeout(() => {
                                Swal.fire(
                                    'Changed',
                                    'Changed Successfully.',
                                    'success'
                                );
                            }, 2000)
                        }
                    })
                }
            }
        })
    }

    const cancel_handler = (tracking_id) => {

        const token = localStorage.getItem(TOKEN);
        async function callapi() {
            // let result = await getCancelBooking(token, tracking_id);
            // console.log(result);
            // setOrderState(!orderState);
            axios.post(`${BASE_URL}cancel-order-by-pickrr?tracking_id=${tracking_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    console.log("result: ", res)
                    Swal.fire(
                        'Canceled',
                        'Booking Canceled Successfully.',
                        'success'
                    );
                })
                .catch((err) => {
                    alert("Failed")
                })
        }
        console.log(tracking_id);



        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel Booking!'
        }).then((result) => {
            if (result.isConfirmed) {
                callapi();
                // setTimeout(() => {

                // }, 2000)
            }
        })

    }

    const input_handler = (value) => {
        setInputval(value);
    }

    const searchhandle = async () => {
        const token = localStorage.getItem(TOKEN);
        let result = await getSearchOrders(token, inputval)
            .then((res) => {
                setSearRes(res.data)
                if (res.data == undefined) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Data Not Found!',
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            <div className="search-container">
                <div className="searchBar">
                    <input placeholder="Search…" value={inputval} onChange={(event) => {
                        input_handler(event.target.value)
                    }} />
                    {inputval ? <CloseIcon onClick={() => {
                        setInputval('');
                        setSearRes(false);
                    }
                    } style={{ cursor: "pointer" }} /> : null}
                    <SearchIcon onClick={searchhandle} className="search-icon" />

                </div>
            </div>
            {
                spinner ? (
                    <div style={{ padding: '16px' }} >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Customer Name</StyledTableCell>
                                        <StyledTableCell align="right">Address</StyledTableCell>
                                        <StyledTableCell align="right">Number</StyledTableCell>
                                        <StyledTableCell align="right">Pin</StyledTableCell>
                                        <StyledTableCell align="right">Model</StyledTableCell>
                                        <StyledTableCell align="right">Amount</StyledTableCell>
                                        <StyledTableCell align="right">Courier</StyledTableCell>
                                        <StyledTableCell align="right">Employee</StyledTableCell>
                                        <StyledTableCell align="right">Time</StyledTableCell>
                                        <StyledTableCell align="right">Status</StyledTableCell>
                                        <StyledTableCell align="right">Cancel</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!searRes ?
                                        (ordersDetails.today_order_list.map((orders) => (
                                            <StyledTableRow key={orders._id.$oid}>
                                                <StyledTableCell scope="row">{orders.name}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.address}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.contact_number}
                                                    <EditIcon style={{ fontSize: "18px", cursor: "pointer", marginLeft: "10px" }}
                                                        onClick={() => {
                                                            editNumber_handler(orders._id.$oid, orders.contact_number)
                                                        }
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{orders.pin_code}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.car_model}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.cod_amount}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.courier}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.employee_name}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.current_status_time}</StyledTableCell>
                                                <StyledTableCell align="right">{orders.current_status_type}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Button variant="outlined" className="cancel-btn"
                                                        onClick={() => cancel_handler(orders.tracking_id)}
                                                    >Cancel
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))) : (
                                            <StyledTableRow key={searRes._id.$oid}>
                                                <StyledTableCell scope="row">{searRes.name}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.address}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.contact_number}
                                                    <EditIcon style={{ fontSize: "18px", cursor: "pointer", marginLeft: "10px" }}
                                                        onClick={() => {
                                                            editNumber_handler(searRes._id.$oid, searRes.contact_number)
                                                        }
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{searRes.pin_code}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.car_model}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.cod_amount}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.courier}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.employee_name}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.current_status_time}</StyledTableCell>
                                                <StyledTableCell align="right">{searRes.current_status_type}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Button variant="outlined" className="cancel-btn"
                                                        onClick={() => cancel_handler(searRes._id.$oid)}
                                                    >Cancel
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                            <div style={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                <Stack spacing={2}>
                                    <Pagination
                                        count={ordersDetails.total_pages}
                                        page={page}
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </div>
                        </TableContainer>
                    </div>
                ) : (
                    <Dna
                        visible={true}
                        height="200"
                        width="200"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                )
            }

        </>

    )
}

export default OrdersTable