import React, { useState, useContext, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';

import TLComponents from '../Components/EmployeesComponents/TLComponents';
import EmpComponents from '../Components/EmployeesComponents/EmpComponents';

import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchRes from '../Components/SearchComponents/SearchRes';

import { getSearchEmp } from '../API/CallsApi';
import { TOKEN } from '../API/Index';
import { GlobalData } from '../App';

function TabPanel(TabPanelProps) {
    const { children, value, index } = TabPanelProps;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AllEmployees() {
    const { sarchState } = useContext(GlobalData);

    const [value, setValue] = useState(0);
    const [searRes, setSearRes] = useState(false);
    const [inputval, setInputval] = useState('');
    const [search_by, setSearch_by] = useState("Name");
    const [is_name, setIs_name] = useState(true);
    const [is_tl, setIs_tl] = useState(false);


    const Search = styled("div")(({ theme }) => ({
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(1),
            width: "auto"
        }
    }));

    const SearchIconWrapper = styled("div")(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: "inherit",
        "& .MuiInputBase-input": {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "12ch",
                "&:focus": {
                    width: "20ch"
                }
            }
        }
    }));

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setIs_tl(false)
        } else {
            setIs_tl(true)
        }
    };

    const HandleSearchBy = (event) => {
        setSearch_by(event.target.value)
        if (event.target.value == 'Name') {
            setIs_name(true)
        } else {
            setIs_name(false)
        }
    };

    const input_handler = (value) => {
        setInputval(value);
    }

    const searchhandle = async () => {
        const token = localStorage.getItem(TOKEN);
        let result = await getSearchEmp(token, is_name, is_tl, inputval)
        .then((res) => {
            if(res.data){
                setSearRes(res);
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        searchhandle();
    }, [sarchState])
    
    return (
        <div className="Employees-Components">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{ display: "flex", justifyContent: "space-between" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Employees" {...a11yProps(0)} />
                    <Tab label="Team Lead" {...a11yProps(1)} />
                </Tabs>
                <div className="search-bar" style={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "center" }}>
                    <FormControl style={{ width: "6rem" }}>
                        <InputLabel id="demo-simple-select-label" >Search By</InputLabel>
                        <Select onChange={HandleSearchBy} style={{ height: "1.5rem", fontSize: "20px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={search_by}
                            label="Search By"
                        >
                            <MenuItem value="Name">Name</MenuItem>
                            <MenuItem value="Number">Number</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="searchBar">
                        <input placeholder="Search…" value={inputval} onChange={(event) => {
                            input_handler(event.target.value)
                        }} />
                        {inputval ? <CloseIcon onClick={() => {
                            setInputval('');
                            setSearRes(false);
                        }
                        } style={{ cursor: "pointer" }} /> : null}
                        <SearchIcon onClick={searchhandle} className="search-icon" />

                    </div>
                </div>
            </Box>
            {
                searRes ? (
                    <TabPanel>
                        <SearchRes indexes={value} searResult={searRes} />
                    </TabPanel>
                ) : (
                    <>
                        <TabPanel value={value} index={0}>
                            <EmpComponents />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <TLComponents />
                        </TabPanel>
                    </>
                )
            }

        </div>
    )
}

export default AllEmployees;
