import React from 'react';
import ProfileBox from '../Components/Profile/ProfileBox';
function Profile() {
  return (
    <div className="Profile-Components">
      <ProfileBox />
    </div>
  )
}

export default Profile;