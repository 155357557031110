import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { TOKEN } from '../../API/Index';
import { getAllEmpData, getEplStates } from '../../API/CallsApi';
import { Dna } from 'react-loader-spinner';
import Swal from 'sweetalert2';

import { GlobalData } from '../../App';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function EmpComponents() {

  const { empState, setEmpState } = useContext(GlobalData);
  const [page, setPage] = useState(1);
  const [empDetails, setEmpDetails] = useState(false);
  const [dataPP, setDataPP] = useState(10);
  const [spinner, setSpinner] = useState(false);

  async function callAPI() {
    setSpinner(false);
    const token = localStorage.getItem(TOKEN);
    let result = await getAllEmpData(token, page, dataPP)
    .then((res) => {
      setEmpDetails(res.data);
      setSpinner(true);
    })
    .catch((err) =>{
      console.log("Page Emp", err)
      setSpinner(false);
    })
  }

  useEffect(() => {
    callAPI();
  }, [page]);

  useEffect(() => {
    callAPI();
  }, [empState]);


  const handleChange = (event, value) => {
    setPage(value);
  };

  const status_handler = (_id, status) => {

    const token = localStorage.getItem(TOKEN);
    async function callapi() {
      let result = await getEplStates(token, _id, !status)
        .then((res) => {
          setEmpState(status);
        })
        .catch((err) => {
          console.log("Employees Status : ", err)
        })
    }

    if (status) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deactivate!'
      }).then((result) => {
        if (result.isConfirmed) {
          callapi();
          setTimeout(() => {
            Swal.fire(
              'Deactivate',
              'Deactivated Successfully.',
              'success'
            );
          }, 2000);
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activated!'
      }).then((result) => {
        if (result.isConfirmed) {
          callapi();
          setTimeout(() => {
            Swal.fire(
              'Activated!',
              'Activated Successfully.',
              'success'
            );
          }, 2000);
        }
      })
    }
  }

  return (
    <>
      {
        spinner ? (
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="right">Number</StyledTableCell>
                  <StyledTableCell align="right">Team Lead Name</StyledTableCell>
                  <StyledTableCell align="right">Pending Orders</StyledTableCell>
                  <StyledTableCell align="right">Today Orders</StyledTableCell>
                  <StyledTableCell align="right">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{
                empDetails.emp_list.map((emp) => (
                  <StyledTableRow key={emp._id.$oid}>
                    <StyledTableCell scope="row">
                      {emp.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{emp.mobile_no}</StyledTableCell>
                    <StyledTableCell align="right">{emp.tl_name}</StyledTableCell>
                    <StyledTableCell align="right">{emp.pending_orders}</StyledTableCell>
                    <StyledTableCell align="right">{emp.active_order_count}</StyledTableCell>
                    <StyledTableCell align="right">{emp.verified}
                      {
                        emp.verified ? (
                          <button className="Switch-btn active"
                            onClick={
                              () => {
                                status_handler(emp._id.$oid, emp.verified)
                              }
                            }
                          >Active</button>
                        ) : (
                          <button className="Switch-btn inactive"
                            onClick={
                              () => {
                                status_handler(emp._id.$oid, emp.verified)
                              }
                            }
                          >Inactive</button>
                        )
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Stack spacing={2}>
                <Pagination
                  count={empDetails.total_pages}
                  page={page}
                  onChange={handleChange}
                />
              </Stack>
            </div>
          </TableContainer>
        ) : (
          <Dna
            visible={true}
            height="200"
            width="200"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )
      }
    </>
  )
}

export default EmpComponents;