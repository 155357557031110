import React from 'react';
import OrdersTable from '../Components/OrdersComponents/OrdersTable';
function AllOrders() {
  return (
    <div className="AllOrders-Components">
        <OrdersTable/>
    </div>
  )
}

export default AllOrders