import React, { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import * as yup from "yup";
import { TOKEN } from '../../API/Index';
import { useNavigate } from 'react-router-dom';
import { GlobalData } from '../../App';
import { getLogin } from '../../API/CallsApi';

const schema = yup.object({
    mobile_no: yup.string().required("Enter Valid Number"),
    password: yup.string().required("Enter Valid Password"),
}).required();

const Login = () => {
    const { GetAuthlog, authLog } = useContext(GlobalData);
    const naviget = useNavigate();

    useEffect(() => {
        if (authLog) {
            naviget("/")
        }
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {

        let result = await getLogin(data)
            .then((res) => {
                if (res.statusText === "OK") {
                    Swal.fire({
                        icon: 'success',
                        title: 'Good job!',
                        text: 'Logged in Successfully!',
                    })
                    localStorage.setItem(TOKEN, res.data.token);
                    localStorage.setItem("admin_name", res.data.Name.split(' ')[0]);
                    GetAuthlog(localStorage.getItem(TOKEN));
                    naviget('/');
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Login Faild, Please Try again!',
                        footer: res.response.data.detail,
                    })
                }
            })
            .catch((err) => {
                console.log("Err : ", err);
            })
    };

    return (
        <div className="Adminlog-components">
            <div className="login-container">

                <h2 className="brandname">ROAD<span>SERVE</span></h2>
                <p className="brandtag">Fast | Reliable | Cheap</p>
                <p className="form-heading">Login</p>
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <div className="items">
                        <input type="number" placeholder="Mobile Number" name="mobile_no" {...register("mobile_no")} className="input number-fild" />
                        <p>{errors.mobile_no?.message}</p>
                    </div>

                    <div className="items">
                        <input type="password" placeholder="Password" name="password" {...register("password")} className="input" />
                        <p>{errors.password?.message}</p>
                    </div>
                    <button type="submit" className="login-ntn">Login</button>
                    <p>Forget password? <span
                        onClick={
                            () => naviget('/forgot-password')
                        }
                    >Click here</span></p>
                </form>
                <div className="register-hear">
                    <h5>Or</h5>
                    <p>You don't have account? <span
                        onClick={
                            () => naviget('/register')
                        }
                    >Click here</span></p>
                </div>
            </div>
        </div>
    );
};

export default Login;