import React from 'react';

function Notifications() {
  return (
    <div className="Notifications-Components">
      Notifications
    </div>
  )
}

export default Notifications;