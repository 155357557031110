import axios from "axios";
import {
    TOKEN,
    BASE_URL,
    LOGIN_URL,
    REGISTER_URL,
    ALL_COUNT_URL,
    CHANGE_NUMBER_URL,
    EMP_ACTIVATE_URL,
} from "./Index";

const Bearer = localStorage.getItem(TOKEN);


export function getLogin(data) {
    return axios.post(LOGIN_URL, data)
        .then((res) => {
            // console.log(res)
            return res;
        })
        .catch((err) => {
            // console.log(err)
            return err;
        })
}

export function getRegister(data) {
    return axios.post(REGISTER_URL, data)
        .then((res) => {
            // console.log(res)
            return res;
        })
        .catch((err) => {
            // console.log(err)
            return err;
        })

}

export function getAllTLData(token, page, datapp) {
    return axios.get(`${BASE_URL}get_all_emp_data?is_tl=true&page=${page}&page_size=${datapp}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getAllEmpData(token, page, datapp) {
    return axios.get(`${BASE_URL}get_all_emp_data?is_tl=false&page=${page}&page_size=${datapp}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getAllConut(token) {
    return axios.get(ALL_COUNT_URL, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getAllCustomerData(token, page, datapp) {
    return axios.get(`${BASE_URL}admin_order?page=${page}&page_size=${datapp}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getAllOrderData(token, page, datapp) {
    return axios.get(`${BASE_URL}today_order_list?page=${page}&page_size=${datapp}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getEplDetails(token, emp_id) {
    return axios.get(`${BASE_URL}emp_details?${emp_id}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getEplStates(token, id, state) {
    return axios.put(EMP_ACTIVATE_URL, {
        emp_id: id,
        IsActive: state
    }, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getChangeNumber(token, id, value) {
    return axios.put(CHANGE_NUMBER_URL, {
        order_id: id,
        new_number: value
    }, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            console.log(res)
            return res;
        })
        .catch((err) => {
            console.log(err);
            return err;
        })
}

export function getCancelBooking(token, tracking_id) {
    console.log('tracking_id', tracking_id);
    tracking_id.toString();
    return axios.post(`${BASE_URL}cancel-order-created-by-pickrr?tracking_id=${tracking_id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            console.log(res)
            return res;
        })
        .catch((err) => {
            console.log(err);
            return err;
        })
}

export function getSearchEmp(token, is_name, is_tl, inputvalue) {
    let searchby;
    if (is_name) {
        searchby = "name"
    } else {
        searchby = "mobile_no"
    }
    return axios.get(`${BASE_URL}search?${searchby}=${inputvalue}&is_tl=${is_tl}&is_name=${is_name}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

export function getSearchOrders(token, inputvalue) {

    return axios.get(`${BASE_URL}search_order?mobile_no=${inputvalue}`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}