import React, { useEffect, useState } from 'react';
import HomeComponents from '../Components/HomeComonents/HomeComponents';
import { getAllConut } from '../API/CallsApi';
import { GlobalData } from '../App';
import { useContext } from 'react';
import { Dna } from 'react-loader-spinner';
import { TOKEN } from '../API/Index';

function Dashbord() {
  const [spinner, setSpinner] = useState(false);
  const { setAllCounts } = useContext(GlobalData);

  useEffect(() => {
    const Bearer = localStorage.getItem(TOKEN);
    async function callapi() {
      let result = await getAllConut(Bearer);
      setAllCounts(result.data)
      if (result.data) {
        setSpinner(true);
      } else {
        setSpinner(false);
      }
    }
    callapi();
    setInterval(() => {
      callapi();
    }, 300000);
  }, []);

  return (
    <div className="Dashbord-Main-container">
      {spinner ? <HomeComponents /> :
        <Dna
          visible={true}
          height="200"
          width="200"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      }

    </div>
  )
}

export default Dashbord
