
import {FiHome, FiDollarSign, FiSettings, FiHeadphones} from 'react-icons/fi';
import { FcHome, FcOnlineSupport, FcInternal, FcViewDetails } from "react-icons/fc";
export const items = [
    {
        name:'Home',
        icons: <FcHome/>,
        link:"/"
    },
    {
        name:'Employess',
        icons: <FcOnlineSupport/>,
        link:"/employees"
    },
    {
        id:3,
        name:'Orders',
        icons: <FcInternal/>,
        link:"/orders"
    },
    {
        id:4,
        name:'Datas',
        icons: <FcViewDetails/>,
        link:"/orders/by-admin"
    }
];