import React, { useContext} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { getEplStates } from '../../API/CallsApi';
import { TOKEN } from '../../API/Index';
import Swal from 'sweetalert2';

import { GlobalData } from '../../App';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 20,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));


function SearchRes(props) {

    const {setSearchState} = useContext(GlobalData);
    const status_handler = (_id, status) => {
        const token = localStorage.getItem(TOKEN);

        async function callapi() {
            let result = await getEplStates(token, _id, !status);
            setSearchState(status);
        }

        if (status) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Deactivate!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setTimeout(() => {
                        callapi();
                    }, 2000)

                    Swal.fire(
                        'Deactivate',
                        'Deactivated Successfully.',
                        'success'
                    );
                }
            })
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Activated!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setTimeout(() => {
                        callapi();
                    }, 2000)
                    Swal.fire(
                        'Activated!',
                        'Activated Successfully.',
                        'success'
                    );
                }
            })
        }
    }


    return (
        <div>
            {
                props.indexes === 0 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align="right">Number</StyledTableCell>
                                    <StyledTableCell align="right">Team Lead Name</StyledTableCell>
                                    <StyledTableCell align="right">Pending Orders</StyledTableCell>
                                    <StyledTableCell align="right">Today Orders</StyledTableCell>
                                    <StyledTableCell align="right">Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.searResult.data ? (
                                    <StyledTableRow key={props.searResult.data._id.$oid}>
                                        <StyledTableCell scope="row">
                                            <Link to={`/employees/details`} state={{ id: props.searResult.data }} style={{ textDecoration: "none", color: "black" }}>
                                                {props.searResult.data.name}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.mobile_no}</StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.tl_name}</StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.pending_orders}</StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.active_order_count}</StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.verified}
                                            {
                                                props.searResult.data.verified ? (
                                                    <button className="Switch-btn active"
                                                        onClick={
                                                            () => {
                                                                status_handler(props.searResult.data._id.$oid, props.searResult.data.verified)
                                                            }
                                                        }
                                                    >Active</button>
                                                ) : (
                                                    <button className="Switch-btn inactive"
                                                        onClick={
                                                            () => {
                                                                status_handler(props.searResult.data._id.$oid, props.searResult.data.verified)
                                                            }
                                                        }
                                                    >Inactive</button>
                                                )
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : null
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align="right">Number</StyledTableCell>
                                    <StyledTableCell align="right">Employee count</StyledTableCell>
                                    <StyledTableCell align="right">Email</StyledTableCell>
                                    <StyledTableCell align="right">Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.searResult.data ? (
                                    <StyledTableRow key={props.searResult.data._id.$oid}>
                                        <StyledTableCell scope="row">
                                            <Link to={`/employees/details`} state={{ id: props.searResult.data }} style={{ textDecoration: "none", color: "black" }}>
                                                {props.searResult.data.name}
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.mobile_no}</StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.empployee_count}</StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.email}</StyledTableCell>
                                        <StyledTableCell align="right">{props.searResult.data.verified}
                                            {
                                                props.searResult.data.verified ? (
                                                    <button className="Switch-btn active"
                                                        onClick={
                                                            () => {
                                                                status_handler(props.searResult.data._id.$oid, props.searResult.data.verified)
                                                            }
                                                        }
                                                    >Active</button>
                                                ) : (
                                                    <button className="Switch-btn nactive"
                                                        onClick={
                                                            () => {
                                                                status_handler(props.searResult.data._id.$oid, props.searResult.data.verified)
                                                            }
                                                        }
                                                    >Inactive</button>
                                                )
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : null
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }

        </div>
    )
}

export default SearchRes